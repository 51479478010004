import React, { useState, useEffect } from "react";
import "./SavedJobs.css";
import isAuth from "../../Utils/isAuth";
import { VscLocation } from "react-icons/vsc";
import { ImStack } from "react-icons/im";
import { SlGraduation } from "react-icons/sl";
import { TbCurrencyDollar } from "react-icons/tb";
import { BsGenderAmbiguous } from "react-icons/bs";
import { BsBookmarkFill } from "react-icons/bs";
import { BsBookmark } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import useSavedJobs from "../../hooks/useSavedJobs";
import {useDispatch} from "react-redux";
function JobListCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [saved, setSaved] = useState(props.saved);
  const [islogedin, setislogedin] = useState(false);

  const { saveJob, unsaveJob } = useSavedJobs({
    onSaveSuccess: () => {
      setSaved(true);
    },
    onUnsaveSuccess: () => {
      setSaved(false);
    },
  });

  useEffect(() => {
    setislogedin(isAuth(dispatch));
  }, [islogedin]);

  const handleremove = () => {
    if (islogedin) {
      unsaveJob({
        id: props.id,
      });
    } else {
      navigate("/login");
    }
  };
  const handleadd = () => {
    if (islogedin) {
      saveJob({
        job_id: props.id,
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="SavedJobsCard_container">
      <div>
        {" "}
        <img
          src={process.env.REACT_APP_API + "/company/" + props.image}
          alt="Image"
          className="Libanlogo"
          onClick={() => navigate(`/jobdetails/${props.id}`)}
        />{" "}
      </div>
      <div
        className="MARKETING_CONT1"
        onClick={() => navigate(`/jobdetails/${props.id}`)}
      >
        <div className="MARK_TITLE">{props.position}</div>
        <div
          //red or green
          className="STATUS"
          style={{ color: props.expired ? "red" : "green" }}
        >
          {props.expired ? "Expired" : "Available"}
        </div>
        <div className="MARK_NAME">{props.name} </div>
        <div className="info_card_cont">
          <div className="MARK_EXPERIENCE">
            <VscLocation size={25} />
            &nbsp; {props.location}
          </div>
          <div className="MARK_EXPERIENCE">
            {" "}
            <ImStack size={20} /> &nbsp; {props.experience}
          </div>
          <div className="MARK_EXPERIENCE">
            <SlGraduation size={20} /> &nbsp; {props.education}
          </div>
          <div className="MARK_EXPERIENCE">
            <BsGenderAmbiguous size={20} /> &nbsp; {props.gender}
          </div>
          <div className="MARK_EXPERIENCE">
            <TbCurrencyDollar size={20}/> &nbsp; {props.payment.replace("&lt;", "<")}
          </div>
        </div>
      </div>

      <div className="MARKETING_CONT2">
        <div>
          {saved ? (
            <div className="BOOKMARK_ICON" onClick={handleremove}>
              {" "}
              <BsBookmarkFill size={25} /> Saved
            </div>
          ) : (
            <div className="BOOKMARK_ICON" onClick={handleadd}>
              {" "}
              <BsBookmark size={25} /> Save
            </div>
          )}
        </div>

        <button className="CONT2_BUTTON"> {props.type} </button>
        <div className="POSTDATE"> Post Date: {props.date} </div>
      </div>
    </div>
  );
}

export default JobListCard;
