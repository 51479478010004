import React, { useState, useEffect, useRef } from "react";
import "./CvBuilder.css";
import heading from "../../assets/images/contactus/headingc.png";
import useAsync from "../../hooks/useAsync";
import mehnatiApi from "../../api";
import notifyUtils from "../../Utils/notify";
import PersonalInformation from "./PersonalInformation";
import AcademicAchievements from "./AcademicAchievments";
import ProfessionalExperience from "./ProfessionalExperience";
import Language from "./Language";
import Skills from "./Skills";
import OtherActivities from "./OtherActivities";

function CvBuilder() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    school: "",
    email: "",
    phone: "",
    profile: "",
    education: [{ school: "", degree: "", startDate: "", endDate: "", present: false, specialization: "", location: "" }],
    experiences: [{ title: "", company: "", startDate: "", endDate: "", present: false, description: "", location: "" }],
    skills: [{ id: 1, name: "" }],
    languages: [{ id: 1, name: "", proficiency: "" }],
    activities: [{ id: 1, name: "" }],
  });

  useEffect(()=>{
    console.log(formData)
  },[formData])

  const [errors, setErrors] = useState({});
  const [displayErrors, setDisplayErrors] = useState(false)
  const [isValid, setIsValid] = useState(false);

  // Refs for sections
  const sectionRefs = {
    personal: useRef(null),
    education: useRef(null),
    experience: useRef(null),
    languages: useRef(null),
    skills: useRef(null),
    activities: useRef(null),
  };

  const validateFields = () => {
    let newErrors = {}


    if (!formData.firstName.trim()) newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!formData.address.trim()) newErrors.address = "Address is required";
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Valid email is required";
    }
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    if (!formData.school.trim()) newErrors.school = "School/University is required";
    if (!formData.profile.trim()) newErrors.profile = "Profile description is required";



    formData.education.forEach((edu, index) => {
      let eduErrors = {};
      if (!edu.school.trim()) eduErrors.school = "School name is required";
      if (!edu.degree.trim()) eduErrors.degree = "Degree is required";
      if (!edu.specialization.trim()) eduErrors.specialization = "Specialization is required";
      if (!edu.location.trim()) eduErrors.location = "Location is required";
      if (!edu.startDate) eduErrors.startDate = "Start date is required";
      if (!edu.present && !edu.endDate) eduErrors.endDate = "End date is required if not currently studying";


      if (Object.keys(eduErrors).length > 0) {
        if(!newErrors.education)
          newErrors.education = formData.education.map(() => ({}));
        newErrors.education[index] = eduErrors;
      }
    });



    formData.languages.forEach((lang, index) => {
      let langErrors = {};
      if (!lang.name.trim()) langErrors.name = "Language name is required";
      if (!lang.proficiency.trim()) langErrors.proficiency = "Proficiency level is required";

      if (Object.keys(langErrors).length > 0) {
        if(!newErrors.language){
          // Ensure newErrors.languages is an array before assigning values
          newErrors.languages = formData.languages.map(() => ({}));
        }
        newErrors.languages[index] = langErrors;
      }
    });

    // Ensure newErrors.skills is an array before assigning values

    formData.skills.forEach((skill, index) => {
      let skillErrors = {};
      if (!skill.name.trim()) skillErrors.name = "Skill name is required";

      if (Object.keys(skillErrors).length > 0) {
        if(!newErrors.skills){
          newErrors.skills = formData.skills.map(() => ({}));
        }
        newErrors.skills[index] = skillErrors;
      }
    });

    // Ensure newErrors.activities is an array before assigning values

    formData.activities.forEach((activity, index) => {
      let activityErrors = {};
      if (!activity.name.trim()) activityErrors.name = "Activity name is required";

      if (Object.keys(activityErrors).length > 0) {
        if(!newErrors.activities){
          newErrors.activities = formData.activities.map(() => ({}));
        }
        newErrors.activities[index] = activityErrors;
      }
    });


    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0);
    return newErrors;
  };


  useEffect(() => {
    if(displayErrors)
    validateFields();
  }, [formData]);

  const handleInputChange = (section, index, field, value) => {
    setFormData((prevData) => {
      let updatedSection = [...prevData[section]];

      if (field === "remove") {
        updatedSection = updatedSection.filter((_, i) => i !== index);
      } else if (index >= updatedSection.length) {
        const newId = updatedSection.length > 0
            ? Math.max(...updatedSection.map(item => item.id || 0)) + 1
            : 1;

        updatedSection.push({ ...value, id: newId });
      } else {
        updatedSection[index][field] = value;
      }

      return { ...prevData, [section]: updatedSection };
    });
  };

  const handleSubmit = () => {
    setDisplayErrors(true);
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      notifyUtils.error("Please fill all required fields correctly");

      // Define a mapping from field names to section refs
      const fieldToSectionMap = {
        firstName: "personal",
        lastName: "personal",
        address: "personal",
        email: "personal",
        phone: "personal",
        school: "personal",
        profile: "personal",
        education: "education",
        languages: "languages",
        skills: "skills",
        activities: "activities",
      };

      const firstErrorField = Object.keys(newErrors).find((key) => {
        if (Array.isArray(newErrors[key])) {
          return newErrors[key].some((item) => Object.keys(item).length > 0);
        }
        return newErrors[key] && newErrors[key] !== "";
      });

      console.log("First error field:", firstErrorField);

      const firstErrorSection = fieldToSectionMap[firstErrorField];

      if (firstErrorSection && sectionRefs[firstErrorSection]?.current) {
        sectionRefs[firstErrorSection].current.scrollIntoView({ behavior: "smooth", block: "start" });
      }

      return;
    }

    createCV({
      first_name: formData.firstName,
      last_name: formData.lastName,
      school_name: formData.school,
      objective: formData.profile ? [formData.profile] : [],
      location: formData.address,
      email: formData.email,
      phone: formData.phone,
      education: formData.education.map((edu) => ({
        degree: edu.degree,
        school_name: edu.school,
        specialization: edu.specialization || "",
        location: edu.location || "",
        period: {
          start: edu.startDate,
          end: edu.present ? "Present" : edu.endDate,
        },
      })),
      experience: formData.experiences.map((exp) => ({
        company_name: exp.company,
        position: exp.title,
        location: exp.location || "",
        period: {
          start: exp.startDate,
          end: exp.present ? "Present" : exp.endDate,
        },
        main_duties: exp.description
            ? exp.description.split("\n").map(duty => `${duty.trim()}`)
            : [],
      })),
      languages: formData.languages.map((lang) => ({
        language_name: lang.name,
        level: lang.proficiency,
      })),
      skill: formData.skills.map((skill) => skill.name),
      activities: formData.activities.map((activity) => activity.name),
    });
  };

  const { main: createCV, result: cv, loading: loadingCreateCV , error} = useAsync({
    fn: mehnatiApi.createCV,
    onSuccess: () =>{
      notifyUtils.success("CV created successfully")
      window.open(process.env.REACT_APP_API + "/CV/" + cv?.cv, '_blank');
    },
    onError: () => {
      notifyUtils.error(error ?? "Failed to create cv")
    },
  });


  return (
      <div className="Main_div_cv">
        <div className="Aboutus_heading">
          <div className="Aboutus_heading_img">
            <img src={heading} alt="heading" className="heading"/>
          </div>
          <div className="Aboutus_heading_text">
            <div className="Aboutus_heading_text_title"> Create Your CV</div>

            <div className="Aboutus_heading_text_desc">
              <a href="/">Home /</a>
              <span className="head_span">Cv Builder</span>
            </div>
          </div>
        </div>
        <div className="cv_container">
          <div className="cv_head">
            <div className="cv_title">Curriculum Vitae (CV)</div>
          </div>
          <div className="underline_cv"/>
          <div className="cv_title_text">
            Now you can create your CV in a few minutes
          </div>
          <div className="underline_cv"/>
          <div className="cv_text_conts">
            <div className="cv_text_cont">
              <div className="cv_text_title2">The context</div>
              <div className="cv_text_contents">
                The Mehnati platform aims to help you concretely enter the job
                market. As the step of creating a CV can be one of the most
                complicated at the beginning of a career, our experts have decided
                to accompany you in this step by creating a special page on the
                site through which you will get your CV. And now you ve arrived on
                this page!
              </div>
            </div>
            <div className="cv_text_cont">
              <div className="cv_text_title2">How to use this tool</div>
              <div className="cv_text_contents">
                Simply fill in the fields with your personal information, then
                upload the content to get your CV in a few moments. You can then
                print it out or send it by email to apply to any of the companies
                you are interested in.
              </div>
            </div>
            <div className="cv_text_cont">
              <div className="cv_text_title2">
                Do you have problems in CV content?
              </div>
              <div className="cv_text_contents">
                Here are some helpful CV tips.
              </div>
            </div>
            <div className="underline_cv margin"/>
          </div>
        </div>
        <div className="cv_builder_container">
          <div ref={sectionRefs.personal}>
            <PersonalInformation formData={formData} errors={errors} setFormData={setFormData}/>
          </div>
          <div ref={sectionRefs.education}>
            <AcademicAchievements formData={formData} errors={errors} handleInputChange={handleInputChange}
                                  setFormData={setFormData}/>
          </div>
          <div ref={sectionRefs.experience}>
            <ProfessionalExperience formData={formData} handleInputChange={handleInputChange}/>
          </div>
          <div ref={sectionRefs.languages}>
            <Language formData={formData} handleInputChange={handleInputChange}/>
          </div>
          <div ref={sectionRefs.skills}>
            <Skills formData={formData} handleInputChange={handleInputChange}/>
          </div>
          <div ref={sectionRefs.activities}>
            <OtherActivities formData={formData} handleInputChange={handleInputChange}/>
          </div>
          <div className="button_generation">
            <button className="generate_but" disabled={loadingCreateCV} onClick={handleSubmit}>
              {loadingCreateCV ? "Generating your CV..." : "Generate CV"}
            </button>

          </div>
        </div>
      </div>
  );
}

export default CvBuilder;
